@tailwind base;
@tailwind components;
@tailwind utilities;

img {
  object-fit: cover;
}

.content {
  max-width: 1440px;
}

.rectangleTeal {
  opacity: 0.1;
  background: var(--Color-2, #18978b);
  filter: blur(90px);
  width: 192.964px;
  height: 280.951px;
  transform: rotate(42.782deg);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animated-svg {
  animation: rotate 10s infinite linear; /* Adjust duration and other properties as needed */
}

/* Hamburger Menu */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle {
  position: absolute;
  width: 24px;
  height: 2px;
  top: 0;
  left: 0;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-bottom {
  position: absolute;
  width: 16px;
  height: 2px;
  top: 0;
  right: 0;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  margin-right: 2px;
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
  width: 24px;
}

.hoverEffect li p {
  position: relative;
  transition: 0.5s;
}

.hoverEffect:hover li p {
  opacity: 0.5;
}

.hoverEffect li p:hover {
  opacity: 1;
}

.ant-select {
  margin-top: 10px;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: transparent;
  border: none;
}

:where(.css-pr0fja).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
  border: none;
}

:where(.css-pr0fja).ant-select .ant-select-selection-placeholder {
  color: white;
}

:where(.css-pr0fja).ant-select .ant-select-arrow {
  color: white;
}

:where(.css-pr0fja).ant-select-multiple .ant-select-selection-item-content {
  color: white;
}

.anticon {
  color: white;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-select .ant-select-arrow,
:where(.css-dev-only-do-not-override-pr0fja).ant-select
  .ant-select-selection-placeholder {
  color: white;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-select {
  color: white;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-select-multiple
  .ant-select-selection-item-remove {
  color: white;
}